/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Apartmán k pronájmu v Bulharsku"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="css-oremi0 --style3 --full pb--20" anim={""} name={"uvod"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box fs--102 w--300 swpf--uppercase ls--0 lh--1" content={"<span style=\"color: black\">APARTMán V bytovém domě RUSALKA<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace-1"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box fs--62" style={{"maxWidth":614}} content={"<span style=\"color: var(--color-dominant);\">Vaše <span style='font-style: italic;'>pohodlí</span> je pro nás prvořadé</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--3 pb--08 pt--16" anim={"2"} animS={"8"} style={{"maxWidth":1300}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"2"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Moře i zábava na dosah<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"Apartmán se nachází v klidné oblasti, odkud je několik kroků k moři, do parku nebo do historického centra městečka. Děti ocení letní atrakce.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={""} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Plně vybaveno</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"Kuchyně je plně vybavena, takže je možné se rozhodnout, zda vyrazit do některé z mnoha blízkých hospůdek či si připravit pohodně jídlo doma. Samozřejmostí je pračka, klimatizace v každé místnosti a WI-FI připojení.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"2"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Transfer z letiště<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"Městečko Obzor se nachází zhruba na polovině cesty mezi Varnou a Burgasem, takže je možné vyzvednout hosty na obou letištích. Základní cena za transfer 4 osoby 65 Euro, 6-8 osob 85 Euro. Možnost individuální domluvy dle období, počtu osob možná.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--3 flex--center" anim={"2"} animS={"8"} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pokoj"}>
              </Text>

              <Title className="title-box mt--0" content={"Ložnice 1<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Ložnice s e samostatnou koupelnou s vanou. Samostatný vchod z předsíně.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pokoj"}>
              </Text>

              <Title className="title-box mt--0" content={"Ložnice 2<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Ložnice se samostatnou koupelnou se sprchovým koutem, samostatný vchod z obývacího pokoje.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pokoj"}>
              </Text>

              <Title className="title-box mt--0" content={"Obývací pokoj<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Velký pokoj s kuchyní, 2 pohovky a dostatečný prostor pro umístění dalších 2 postelí pro hosty.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-nb0kjx pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: black\">Rezervujte si svůj termín</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" content={"Nezávazný<br>dotaz<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Městečko OBZOR<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Obzor je typickým dovolenkovým letoviskem.&nbsp;\nDíky orientaci na turistický ruch se však můžete těšit na poskytování služeb té nejvyšší kvality, a to jak u ubytování, tak u stravování či zábavy. Městečko je obklopeno zelení a příjemná je i písečná pláž&nbsp; která se táhne od hranic města, až dále na sever. Moře je tu samozřejmě čisté a v létě příjemně vyhřáté."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Doprava</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Pokud nevyužijete náš transfer nebo si nepůjčíte auto, můžete využít hromadnou dopravu, Obzorem projíždí všechny autobusy a mikrobusy na trase Burgas - Varna. Autobusy jezdí z centra Burgasu, ve Varně je dobré jet z letiště MHD na Atogara Varna a tam se zeptat na bus směrem Obzor a Burgas. Dá se jet i taxíkem, nestojí mnoho.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+420 603 524 941<br>krozboud@gmail.com<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}